import Dom = require("Everlaw/Dom");
import Icon = require("Everlaw/UI/Icon");
import Is = require("Everlaw/Core/Is");
import Toggle = require("Everlaw/UI/Toggle");
import Tooltip = require("Everlaw/UI/Tooltip");
import * as Widget from "Everlaw/UI/Widget";

/**
 * An icon that can be cycled between arbitrary amount of states. Initially at the first one listed,
 * and cycles automatically when clicked.
 */
class CyclingIcon<S extends string = string> extends Widget {
    states: IconState<S>[];
    curState = 0;
    icon: Icon.ActionIcon;
    tooltip: Tooltip;
    onStateChange: (newStateId: string) => void;

    constructor(params: Params<S>) {
        super();
        Object.assign(this, params);
        // The class and alt will be updated in initToggle via _set.
        this.icon = new Icon.ActionIcon(this.current().icon, {
            alt: this.current().alt,
            usePress: params.usePress,
            onClick: params.ignoreOnClick ? undefined : () => this.cycle(),
            makeFocusable: params.makeFocusable,
            focusStyling: params.focusStyling,
        });
        this.tooltip = new Tooltip(this.icon, this.current()?.tooltip);
        if (!this.current()?.tooltip) {
            this.tooltip.disabled = true;
        }

        this.node = this.icon.node;
        this.registerDestroyable(this.icon);
    }

    cycle(): void {
        const prevState = this.current();
        this.curState = this.curState + 1;
        const newState = this.current();
        this.update(prevState, newState);
        this.onStateChange?.(newState.id);
    }

    current(): IconState<S> {
        return this.states[this.curState % this.states.length];
    }

    protected update(prevState: IconState<S>, newState: IconState<S>) {
        Dom.replaceClass(this.node, "icon_" + newState.icon, "icon_" + prevState.icon);
        if (Is.defined(newState.alt)) {
            Icon.setAria(this.node, newState.alt);
        } else {
            Dom.setAttr(this.node, "aria-pressed", String(true));
        }

        if (newState.tooltip) {
            this.tooltip.setContent(newState.tooltip);
            this.tooltip.disabled = false;
        } else {
            this.tooltip.setContent("");
            this.tooltip.disabled = true;
        }
    }

    setState(stateId: string, silent = false) {
        const prevState = this.current();
        for (let i = 0; i < this.states.length; i++) {
            if (this.states[i].id === stateId) {
                this.curState = i;
                break;
            }
        }

        const newState = this.current();
        this.update(prevState, newState);
        !silent && this.onStateChange?.(newState.id);
    }

    setDisabled(state = true) {
        this.icon.setDisabled(state);
    }
}

export interface IconState<S extends string> {
    id: S;
    icon: string;
    alt?: string;
    tooltip?: string;
}

export interface Params<S extends string> extends Toggle.Params {
    usePress?: boolean;
    states: IconState<S>[];
    onStateChange?: (newStateId: S) => void;
    makeFocusable?: boolean;
    focusStyling?: string | string[];
    ignoreOnClick?: boolean;
}

export { CyclingIcon };
