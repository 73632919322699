import { CyclingIcon, IconState } from "Everlaw/UI/CyclingIcon";
import Dom = require("Everlaw/Dom");
import Tooltip = require("Everlaw/UI/Tooltip");
import Util = require("Everlaw/Util");

type CyclingIconStateId = "unselected" | "ASC" | "DESC";

/**
 * A clickable header node that includes a title and sort button.
 * Toggles the sort button on each click.
 */
class SortColumnHeader {
    node: HTMLElement;
    private headerNode: HTMLElement;
    private icon: CyclingIcon<CyclingIconStateId>;
    private tooltip: Tooltip;

    private toDestroy: Util.Destroyable[] = [];

    constructor(header: string, onStateChange: (newStateId: CyclingIconStateId) => void) {
        this.icon = new CyclingIcon<CyclingIconStateId>({
            states: [
                {
                    id: "unselected",
                    icon: "sort-default-20",
                    alt: "sort ascending",
                    tooltip: "Sort ascending",
                },
                {
                    id: "ASC",
                    icon: "sort-asc-20",
                    alt: "sort descending",
                    tooltip: "Sort descending",
                },
                {
                    id: "DESC",
                    icon: "sort-desc-20",
                    alt: "reset sort",
                    tooltip: "Reset sort",
                },
            ],
            onStateChange: (newStateId: CyclingIconStateId) => onStateChange(newStateId),
            makeFocusable: true,
            // This header handles the onClick event already, so no need for the icon
            // to do so as well
            ignoreOnClick: true,
        });
        // Remove the extra "lighten on hover" effect from the button
        // since this header itself already has the effect
        Dom.removeClass(this.icon.getNode(), "action");

        this.headerNode = Dom.div({ class: "header-text" }, header);
        this.node = Dom.div(
            { class: "table-sort-filter-header" },
            Dom.div(
                {
                    class: "sort-clickable",
                    onclick: () => this.cycle(),
                },
                this.headerNode,
                Dom.div({ class: "sort-button" }, this.icon.getNode()),
            ),
        );
        this.tooltip = new Tooltip(this.headerNode, "");
        this.updateTooltipAndHeaderColor();

        this.toDestroy.push(this.icon, this.tooltip);
    }

    getNode(): HTMLElement {
        return this.node;
    }

    current(): IconState<CyclingIconStateId> {
        return this.icon.current();
    }

    cycle(): void {
        this.icon.cycle();
        this.updateTooltipAndHeaderColor();
    }

    setState(state: string, silent = false) {
        this.icon.setState(state, silent);
        this.updateTooltipAndHeaderColor();
    }

    destroy(): void {
        Util.destroy(this.toDestroy);
        this.toDestroy = [];
    }

    private updateTooltipAndHeaderColor(): void {
        if (this.tooltip) {
            this.tooltip.setContent(this.icon.current().tooltip);
            this.tooltip.disabled = this.icon.tooltip.disabled;
        }
        Dom.toggleClass(this.headerNode, "filter-active", this.icon.current().id !== "unselected");
    }
}

export { SortColumnHeader };
